<template>
  <div class="calendly-container">
    <div id="calendly-embed" :style="{ minWidth: '320px', height: '700px' }"></div>
  </div>
</template>

<script>
export default {
  name: 'CalendlyEmbed',
  props: {
    url: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      calendlyScriptLoaded: false
    }
  },
  methods: {
    loadCalendlyScript() {
      if (!this.calendlyScriptLoaded) {
        const script = document.createElement('script')
        script.src = 'https://assets.calendly.com/assets/external/widget.js'
        script.async = true
        document.head.appendChild(script)

        script.onload = () => {
          this.calendlyScriptLoaded = true
          this.initCalendly()
        }
      } else {
        this.initCalendly()
      }
    },
    initCalendly() {
      if (window.Calendly) {
        window.Calendly.initInlineWidget({
          url: this.url,
          parentElement: document.getElementById('calendly-embed'),
          ...this.settings
        })
      }
    }
  },
  mounted() {
    this.loadCalendlyScript()
  },
  beforeUnmount() {
    // Cleanup if needed
    const script = document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]')
    if (script) {
      script.remove()
    }
  }
}
</script>

<style scoped>
.calendly-container {
  width: 100%;
  min-height: 700px;
}
</style>